<template>
  <div class="wxpay_box">
    <h2 class="pdl">收银台</h2>
    <div class="wxpay_order pdr">
      应付金额：<span>{{ wxpayData.price }}</span>
    </div>
    <div class="wxpay_mode pdr pdl">
      <div class="top">
        <div>
          <div class="wx_logo">
            <img src="./img/WePayLogo.png" alt="" />
          </div>
        </div>
        <div>
          支付 <span>{{ wxpayData.price }}</span> 元
        </div>
      </div>
      <div class="rc_box">
        <div
          id="qrcode"
          style="width:200px; height:200px; margin-top:15px;"
        ></div>
        <div class="rc_end">
          <img src="./img/desc.png" alt="" srcset="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import QRCode  from './js/createQC.js'
export default {
  name: "wxpay",
  data() {
    return {
      wxpayData: {}
    };
  },
  created() {
 

    this.wxpayData = JSON.parse(sessionStorage.wxpayData);
    
  },
  mounted() {
      this.   makeCode();
  },
  methods: {
    makeCode() {
      var qrcode = new QRCode(document.getElementById("qrcode"), {
        width: 200,
        height: 200
      });
      var elText = this.wxpayData.rc;

    

      qrcode.makeCode(elText);
    }
  }
};
</script>
<style lang="less">
.wxpay_box {
  .pdl {
    padding-left: 20px;
  }
  .pdr {
    padding-right: 20px;
  }
  h2 {
    height: 50px;
    line-height: 50px;
  }
  .wxpay_order {
    text-align: right;
    background: #f2f2f2;
    height: 50px;
    line-height: 50px;
    span {
      font-weight: 700;
    }
  }
  .wxpay_mode {
    .top {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      span {
        color: #fd8c42;
      }
      > div:nth-child(1) {
        display: flex;
        height: 30px;

        img {
          height: 100%;
        }
      }
    }
    .rc_box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
